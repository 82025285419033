import { template as template_56b49b11cf88447caa0fb542b566bd4e } from "@ember/template-compiler";
import SearchMenuPanel from "../search-menu-panel";
const SearchMenuWrapper = template_56b49b11cf88447caa0fb542b566bd4e(`
  <div class="search-menu glimmer-search-menu" aria-live="polite" ...attributes>
    <SearchMenuPanel @closeSearchMenu={{@closeSearchMenu}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SearchMenuWrapper;
