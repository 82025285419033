import { template as template_af252fc90cd44a81856a4a64432476a4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_af252fc90cd44a81856a4a64432476a4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
