import { template as template_c9a9fc8d7b604410979bdd8cf78ae8d3 } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
const GeneratedInviteLink = template_c9a9fc8d7b604410979bdd8cf78ae8d3(`
  <div>
    <p>{{i18n "user.invited.link_generated"}}</p>
    <p>
      <input
        value={{@link}}
        class="invite-link-input"
        type="text"
        autofocus="autofocus"
      />
    </p>
    {{#if @email}}
      <p>{{i18n "user.invited.valid_for" email=@email}}</p>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GeneratedInviteLink;
