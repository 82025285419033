import { template as template_9218ec7b2983482182c9bad806a8e7ab } from "@ember/template-compiler";
const FKControlMenuContainer = template_9218ec7b2983482182c9bad806a8e7ab(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
